import React from "react";
import Header from "../../components/Header/Header";
import Avatar from "../../assets/icons/avatr.png";
import { useLocation } from "react-router-dom";
import findLocation from "../../utilities/findLocation";
function  App({ show, setSHow, user }) {
  const location = useLocation();
  return (
    <div>
      <Header
        user={user}
        title={findLocation(location.pathname)}
        Avatar={Avatar}
        show={show}
        setSHow={setSHow}
      />
    </div>
  );
}

export default App;
